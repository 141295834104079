import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useScript from "../useScript";
const Video = () => {
	const [gallery, setGallery]=useState([]);
	const [cgallery, setCGallery]=useState([]);

    useEffect(()=>{

	axios.get(window.$api_host+'gallery/index.php?vgalery=1')
    .then(res=> setGallery(res.data))
    .catch(err=> console.log(err));


	axios.get(window.$api_host+'gallery/index.php?vcat=1')
    .then(respons=> setCGallery(respons.data))
    .catch(error=> console.log(error));
    },[])

	
	useScript('https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js');
	useScript(window.$host+"assets/js/libs.min.js");
	useScript(window.$host+"assets/js/gallery.js");
    return (
     
			<main class="main">
		
				<section class="promo-primary">
					<picture>
						<source srcset={window.$host+'/assets/img/typography.jpg'} media="(min-width: 992px)"/><img class="img--bg" src={window.$host+'/assets/img/typography.jpg'} alt="img"/>
					</picture>
					<div class="promo-primary__description"> <span>Charity</span></div>
					<div class="container">
						<div class="row">
							<div class="col-auto">
								<div class="align-container">
									<div class="align-container__item"><span class="promo-primary__pre-title">GICTF</span>
										<h1 class="promo-primary__title"><span>Video</span> <span>Gallery</span></h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
	

				<section class="section gallery">
					<div class="container">
						<div class="row">
							<div class="col-12">
							
								<ul class="filter-panel">
									<li class="filter-panel__item filter-panel__item--active" data-filter="*"><span>All Video</span></li>
									

									{
										cgallery.map((datas, i)=>( 
										<>
									
										<li class="filter-panel__item" data-filter={'.category_'+datas.id}><span>{datas.name}</span></li>	</>	))
										
										
									}
								</ul>
							
							</div>
						</div>
					</div>
					<div class="row gallery-masonry ">
				

						{
								
							gallery.map((data,i)=>{
							
								const vurl = data.v_url.split("v=");
					

							return (<div key={i} class={' col-6 col-md-4 gallery-masonry__item category_'+data.gcategory_id}>
									<div className="promo-pannel promo-pannel11">
										<div className="promo-pannel__video">
										<img className="img--bg"  src={'https://img.youtube.com/vi/'+vurl[1]+'/1.jpg'} alt="image" />
										<a className="video-trigger" href={data.v_url}>
										<span>Watch our video</span><i className="fa fa-play" aria-hidden="true"></i></a>
										</div>
									</div>
								</div>);	
							})
						}   
						
					</div>
				
				</section>
			
			
			</main>
       
    );
};

export default Video;