import axios from 'axios';
import React, { useEffect, useState} from 'react';

import useScript from "../useScript";
const Gallery = () => {
	const [gallery, setGallery]=useState([]);
	const [cgallery, setCGallery]=useState([]);


    useEffect(()=>{
	axios.get(window.$api_host+'gallery/')
    .then(res=> setGallery(res.data))
    .catch(err=> console.log(err));
	axios.get(window.$api_host+'gallery/index.php?cat=1')
    .then(respons=> setCGallery(respons.data))
    .catch(error=> console.log(error));

	

    },[])

	useScript('https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js');
	useScript(window.$host+"assets/js/libs.min.js");
	useScript(window.$host+"assets/js/gallery.js");
	
	
    return (
		
			<main class="main">
        
				<section class="promo-primary">
					<picture>
						<source srcset={window.$host+'/assets/img/typography.jpg'} media="(min-width: 992px)"/><img class="img--bg" src={window.$host+'/assets/img/typography.jpg'} alt="img"/>
					</picture>
					<div class="promo-primary__description"> <span>Charity</span></div>
					<div class="container">
						<div class="row">
							<div class="col-auto">
								<div class="align-container">
									<div class="align-container__item"><span class="promo-primary__pre-title">GICTF</span>
										<h1 class="promo-primary__title"><span>Photo</span> <span>Gallery</span></h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- gallery start--> */}

				<section class="section gallery">
					<div class="container">
						<div class="row">
							<div class="col-12">
							
								<ul class="filter-panel">
									<li className="filter-panel__item filter-panel__item--active"  data-filter="*"><span>All Gallery</span></li>
									
									{
										cgallery.map((datas, i)=>( 
										<>
													
									
										<li className="filter-panel__item" data-filter={'.category_'+datas.id}><span>{datas.name}</span></li>	 </>	))
										
										
									}
								</ul>
							
							</div>
						</div>
					</div>
					<div className="row no-gutters gallery-masonry">
					{
                            gallery.map((data, i)=>( 
								<>
						<div key={i} className={'col-6 col-md-4 gallery-masonry__item category_'+data.gcategory_id}>
							<a className="gallery-masonry__img gallery-masonry__item--height-2" href={window.$image_url+'gallery/'+data.image} data-fancybox="gallery">
								<img className="img--bg" src={window.$image_url+'gallery/'+data.image} alt="img"/>
							<h6 className="gallery-masonry__description">{data.title}</h6></a>
							</div>


</>					))
							}
						
					</div>
				
				</section>
		
			</main>
       
    );
};

export default Gallery;