import React ,{ useState,
	useContext,
	useMemo,
	useEffect,
	createContext,
	Suspense
	 } from 'react';

import { Route,Routes,useLocation} from 'react-router-dom';
import Progress from 'react-progress';
import Footer from './Pages/Shared/Footer/Footer';
import Header from './Pages/Shared/Header/Header';
import Gallery from './Pages/Gallery/Gallery';
import Video from './Pages/Gallery/Video';

function delay(promise, time) {
	return promise.then(
	  result => new Promise(resolve => setTimeout(() => resolve(result), time))
	);
  }
  

  
  const IndicatorContext = createContext();
  
  function IndicatorProvider({ children }) {
	const [active, setActive] = useState(false);
	const value = useMemo(() => ({ active, setActive }), [active, setActive]);
	return (
	  <IndicatorContext.Provider value={value}>
		{children}
	  </IndicatorContext.Provider>
	);
  }
  
  function Indicator() {
	const { active } = useContext(IndicatorContext);
	const [percent, setPercent] = useState(0);
	useEffect(() => {
	  setTimeout(() => {
		setPercent(percent => (percent < 100 ? percent + 10 : 100));
	  }, 200);
	});
	return active ? <Progress percent={percent} color={'red'} height={3}/> : null;
  }
  
  function IndicatorFallback() {
	const { setActive } = useContext(IndicatorContext);
	useEffect(() => {
	  setActive(true);
	  return () => setActive(false);
	});
	return null;
  }

const ltime=500;
const Home = React.lazy(() => delay(import('./Pages/Home/Home'), ltime));
const Activities = React.lazy(() => delay(import('./Pages/Activities/Activities'), ltime));
const Activitydetail = React.lazy(() => delay(import('./Pages/Activities/Activitydetail'), ltime));
const Communities = React.lazy(() => delay(import('./Pages/Communities/Communities'), ltime));
const Events = React.lazy(() => delay(import('./Pages/Events/Events'), ltime));
const Eventdetail = React.lazy(() => delay(import('./Pages/Events/Eventdetail'), ltime));
const News = React.lazy(() => delay(import('./Pages/News/News'), ltime));
const Newsdetail = React.lazy(() => delay(import('./Pages/News/Newsdetail'), ltime));
const Blogsdetail = React.lazy(() => delay(import('./Pages/Blogs/Blogsdetail'), ltime));
const Contacts = React.lazy(() => delay(import('./Pages/Contacts/Contacts'), ltime));
const Project = React.lazy(() => delay(import('./Pages/Project/Project'), ltime));
const Projectdetail = React.lazy(() => delay(import('./Pages/Project/Projectdetail'), ltime));
//const Gallery = React.lazy(() => import('./Pages/Gallery/Gallery'));
//const Video = React.lazy(() => import('./Pages/Gallery/Video'));
const Membership = React.lazy(() => delay(import('./Pages/Membership/Membership'), ltime));
const AboutUs = React.lazy(() => delay(import('./Pages/About/About/AboutUs/AboutUs'), ltime));
const Team = React.lazy(() => delay(import('./Pages/About/About/Team/Team'), ltime));
const WorkPlan = React.lazy(() => delay(import('./Pages/About/WorkPlan/WorkPlan'), ltime));



function App() {
	const { pathname } = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
  return (
    <>
      
		<IndicatorProvider>
      	<Indicator />
		  <Header/>
		<Suspense fallback={<IndicatorFallback />}>
		<Routes>
			 <Route exact path="/" element={<Home/>}/>
			  <Route path="/about" exact={true} element={<AboutUs/>}/>
			  <Route path="/team" exact={true} element={<Team/>}/>
			  <Route path="/workPlan" exact={true} element={<WorkPlan/>}/>
			  <Route path="/activities" exact={true}  element={<Activities/>}/>
			  <Route path="/communities" exact={true}  element={<Communities/>}/>
			  <Route path="/events" exact={true}  element={<Events/>}/>
			  <Route path="/eventdetail/:edid" exact={true}  element={<Eventdetail/>}/>
			  <Route path="/news" exact={true}  element={<News/>}/>
			  <Route path="/newsdetail/:ndid" exact={true}  element={<Newsdetail/>}/>
			  <Route path="/blogdetail/:bdid" exact={true}  element={<Blogsdetail/>}/>
			  <Route path="/project/:id" exact={true}  element={<Project/>}/>
			  <Route path="/projectdetail/:pid" exact={true}  element={<Projectdetail/>}/>
			  <Route path="/contacts" exact={true}  element={<Contacts/>}/>
			  <Route path="/photogallery" exact={true}  element={<Gallery/>}/>
			  <Route path="/videogallery" exact={true}  element={<Video/>}/>
			  <Route path="/membership" exact={true}  element={<Membership/>}/>
			  <Route path='/activitydetail/:aid' exact={true}  element={<Activitydetail/>}/>
		</Routes>
		</Suspense>
		<Footer/>
		</IndicatorProvider>

 
    </>
  );

}

export default App;
