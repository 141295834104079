import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
const Header = (props) => {
    const [contact_mail, setcontact_mail]=useState('');
    const [contact_number, setcontact_number]=useState('');
    const [contact_address, setcontact_address]=useState('');
    const [subMenuOpen, setSubMenuOpen] = useState(-1);

    const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);
    
    useEffect(()=>{
        axios.get(window.$api_host+'data/')
        .then(response=> {
            window.$videolink = response.data.intro_section_video_link;
            setcontact_mail(response.data.contact_mail);
            setcontact_number(response.data.contact_number);
            setcontact_address(response.data.contact_address);
        })
        .catch(error=> console.log(error));
        },[]);

        const [social, setSocial]=useState([]);
        useEffect(()=>{
        axios.get(window.$api_host+'social/')
        .then(res=> setSocial(res.data))
        .catch(err=> console.log(err));
        },[])



        const refreshPage = (e) => {
            const page = e.currentTarget.getAttribute("data-value");
            window.location.reload('/'+page);
          }


    return (
        <div>
         {/* <!-- aside dropdown start--> */}
   
									
        <div className="aside-dropdown">
            <div className="aside-dropdown__inner"><span className="aside-dropdown__close">
                <svg className="icon">
                    <use href="#close" xlinkHref="#close"></use>
                </svg></span>
            <div className="aside-dropdown__item d-lg-none d-block">
                <ul className="aside-menu">
                    <li className="aside-menu__item aside-menu__item--has-child aside-menu__item--active"><Link className="aside-menu__link" to="/" >Home</Link>
                        
                    </li>
                    
                    <li className="aside-menu__item custom_sub"><a className="aside-menu__link" href="javascript:void(0);" onClick={() => toggleMenu(0)} ><span>About</span></a>
                        {/* <!-- sub menu start--> */}
                        <ul className="aside-menu__sub-list" style={{ display: subMenuOpen === 0 ? 'block': 'none'}}>
                                  
                                        <li> <Link to="/about"><span>About</span></Link></li>
                                        <li> <Link to="/workPlan"><span>Work Plan</span></Link></li>
                                        <li><Link to="/team"><span>Team</span></Link></li>
                        </ul>
                        {/* <!-- sub menu end--> */}
                    </li>
                    <li className="aside-menu__item custom_sub"><a className="aside-menu__link"   href="javascript:void(0);" onClick={() => toggleMenu(1)} ><span>Project</span></a>
                        <ul className="aside-menu__sub-list" style={{ display: subMenuOpen === 1 ? 'block': 'none'}}>
                                <li><Link to="/project/54"><span>Ongoing</span></Link></li>
                                <li> <Link to="/project/55"><span>Upcoming</span></Link></li>
                                <li><Link to="/project/56"><span>Completed</span></Link></li>
                        </ul>
                    </li>

                    <li className="aside-menu__item custom_sub"><a className="aside-menu__link" href="javascript:void(0);" onClick={() => toggleMenu(2)}><span>News & Events</span></a>
                        <ul className="aside-menu__sub-list" style={{ display: subMenuOpen === 2 ? 'block': 'none'}}>
                                <li><Link to="/news"><span>News</span></Link></li>
                                <li> <Link to="/event"><span>Events</span></Link></li>
                               
                        </ul>
                    </li>

                    <li className="aside-menu__item custom_sub"><a className="aside-menu__link" href="javascript:void(0);" onClick={() => toggleMenu(3)}><span>Gallery</span></a>
                        <ul className="aside-menu__sub-list" style={{ display: subMenuOpen === 3 ? 'block': 'none'}}>
                                <li><Link to="/photogallery"><span>Photo Gallery</span></Link></li>
                                <li> <Link to="/videogallery"><span>Video Gallery</span></Link></li>
                        </ul>
                    </li>
                    
                </ul>
            </div>
            <div className="aside-dropdown__item">
                {/* <!-- aside menu start--> */}
                <ul className="aside-menu">
                    <li className="aside-menu__item"><Link className="aside-menu__link" to="/communities" >Communities</Link></li>

                    <li className="aside-menu__item"><Link className="aside-menu__link" to="/contacts" >Contacts</Link></li>
                </ul>
                {/* <!-- aside menu end--> */}
                <div className="aside-inner"><span className="aside-inner__title">Email</span>
                <a className="aside-inner__link" href="mailto:support@helpo.org">{contact_mail}</a>
                </div>
                <div className="aside-inner"><span className="aside-inner__title">Phone numbers</span>
                <a className="aside-inner__link" href="tel:+180012345678">{contact_number}</a>
              
                </div>
                <ul className="aside-socials">
                {   
							social.map((data,i)=>{


                                let icon_data = data.icon.replace('fab','fa');
						
									return (
                    <li className="aside-socials__item"><a className="aside-socials__link" target='_blank' href={data.url}><i className={icon_data} aria-hidden="true"></i></a></li>
                    );
                })
            }

          
                </ul>
            </div>
            <div className="aside-dropdown__item">

            <Link to="https://ictunion.org/" target='_blank' className="button button--squared"><span>Membership</span></Link>
           
                
                </div>
            </div>
        </div>
        {/* <!-- aside dropdown end--> */}
        {/* <!-- top bar start--> */}
        <div className="top-bar d-none d-lg-block">
            <div className="container-fluid">
                <div className="row align-items-end">
                    <div className="col-lg-9">
 
                        <Link to="/" className="top-bar__link">Home</Link>
                        <Link to="/contacts" className="top-bar__link">Contact</Link><a className="top-bar__link" href="mailto:support@helpo.org"></a></div>
                    <div className="col-lg-3 text-right">
                        <ul className="socials">
                        {   
							social.map((data,i)=>{


                                let icon_data = data.icon.replace('fab','fa');
						
									return (<li className="socials__item"><a className="socials__link" target='_blank' href={data.url}><i className={icon_data} aria-hidden="true"></i></a></li>
                            
                                    );
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- top bar end--> */}
        {/* <!-- header start--> */}
        
        <header className="header header--front_3">
            <div className="container-fluid">
                <div className="row no-gutters justify-content-between">
                    <div className="col-auto d-flex align-items-center">
                        <div className="dropdown-trigger d-none d-sm-block">
                            <div className="dropdown-trigger__item"></div>
                        </div>
                        <div className="header-logo">
                       <Link to="/" className="header-logo__link">
                        <span>
                            <img className="header-logo__img" src={window.$host+'assets/img/ictu.png'} alt="logo" />
                            </span>
                            </Link>
                            </div>
                    </div>
                    <div className="col-auto">
                        {/* <!-- main menu start--> */}
                        <nav>
                            <ul className="main-menu">
                            
                                <li className="main-menu__item main-menu__item--has-child"><Link to="#" className="main-menu__link"><span>About</span></Link>
                                    {/* <!-- sub menu start--> */}
                                    <ul className="main-menu__sub-list">
                                        
                                        <li> <Link to="/about"><span>About</span></Link></li>
                                        <li> <Link to="/workPlan"><span>Work Plan</span></Link></li>
                                        <li><Link to="/team"><span>Team</span></Link></li>
                                    </ul>

                                    
                                </li>
                                <li className="main-menu__item">
                                    <Link to="/activities" className="main-menu__link"><span>Activities</span></Link>
                                </li>

                                <li className="main-menu__item main-menu__item--has-child"><Link to="#" className="main-menu__link" ><span>Project</span></Link>

                                    <ul className="main-menu__sub-list">
                                        <li><Link to="/project/54"><span>Ongoing</span></Link></li>
                                        <li> <Link to="/project/55"><span>Upcoming</span></Link></li>
                                        <li><Link to="/project/56"><span>Completed</span></Link></li>
                                    </ul>
                                </li>
                                <li className="main-menu__item"><Link to="/communities" className="main-menu__link"><span>Communities</span></Link>

                                </li>

                                <li className="main-menu__item main-menu__item--has-child"><Link to="#" className="main-menu__link" ><span>News & Events</span></Link>
                                    <ul className="main-menu__sub-list">
                                        <li><Link to="/news"><span>News</span></Link></li>
                                        <li> <Link to="/events"><span>Events</span></Link></li>
                                    </ul>
                                </li>

                                <li className="main-menu__item main-menu__item--has-child"><Link to="#" className="main-menu__link" ><span>Gallery</span></Link>
                                    <ul className="main-menu__sub-list">
                                        <li><Link to="/photogallery" onClick={refreshPage} data-value="photogallery"><span>Photo Gallery</span></Link></li>
                                        <li> <Link to="/videogallery" onClick={refreshPage} data-value="videogallery"><span>Video Gallery</span></Link></li>
                                    </ul>
                                </li>
                             

                            </ul>
                        </nav>
                        {/* <!-- main menu end--> */}
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        {/* <!-- lang select start--> 
                        <ul className="lang-select">
                            <li className="lang-select__item lang-select__item--active"><span>En</span>
                                <ul className="lang-select__sub-list">
                                    <li><a href="#">French</a></li>
                                    <li><a href="#">Spanish</a></li>
                                    <li><a href="#">Deutsch</a></li>
                                    <li><a href="#">Russian</a></li>
                                </ul>
                            </li>
                        </ul>*/}
                        {/* <!-- lang select end--> */}
                        <Link to="https://ictunion.org/" target='_blank' className="button button--squared"><span>Membership</span></Link>
                        
                        <div className="dropdown-trigger d-block d-sm-none">
                            <div className="dropdown-trigger__item"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </div>
    );
};

export default Header;