import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter } from "react-router-dom";

//window.$host = 'http://localhost:3000/';

//window.$api_host = 'http://localhost/api_cms/';
//window.$image_url = 'http://localhost/cms/assets/front/img/';

window.$host = 'https://ictunion.org/';

window.$api_host = 'https://api.ictunion.org/';
window.$image_url = 'https://cms.ictunion.org/assets/front/img/';

ReactDOM.render(
  <React.StrictMode>
   <BrowserRouter>
            <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
