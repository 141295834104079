import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
const Footer = () => {
    const [contact_mail, setcontact_mail]=useState('');
    const [contact_number, setcontact_number]=useState('');
    const [contact_address, setcontact_address]=useState('');
    useEffect(()=>{
        axios.get(window.$api_host+'data/')
        .then(response=> {

            setcontact_mail(response.data.contact_mail);
            setcontact_number(response.data.contact_number);
            setcontact_address(response.data.contact_address);
        })
        .catch(error=> console.log(error));
        },[]);


        const [social, setSocial]=useState([]);
        useEffect(()=>{
        axios.get(window.$api_host+'social/')
        .then(res=> setSocial(res.data))
        .catch(err=> console.log(err));
        },[])
    
    return (
        <>
            {/* <!-- footer front_3 start--> */}
            <footer className="footer footer--front_3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="footer-logo"><a className="footer-logo__link" href="index.html"><img className="footer-logo__img" src={window.$host+'assets/img/ictu.png'} alt="logo" /></a></div>
                            <div className="footer-contacts">
                                <p className="footer-contacts__address">{contact_address}</p>
                                <p className="footer-contacts__phone">Phone: <a href={'tel:'+contact_number}>{contact_number}</a></p>
                                <p className="footer-contacts__mail">Email: <a href={'mailto:'+contact_mail}>{contact_mail}</a></p>
                            </div>
                            {/* <!-- footer socials start--> */}
                            <ul className="footer-socials">
                            {   
							social.map((data,i)=>{


                                let icon_data = data.icon.replace('fab','fa');
						
									return (
                                <li className="footer-socials__item"><a className="footer-socials__link" target='_blank' href={data.url}><i className={icon_data} aria-hidden="true"></i></a></li>
                                );
                            })
                        }
                            </ul>
                            {/* <!-- footer socials end--> */}
                        </div>
                        <div className="col-lg-8">
                            {/* <!-- footer nav start--> */}
                            <nav>
                                <ul className="footer-menu">
                                    <li className="footer-menu__item footer-menu__item--active">
                                        <Link className="footer-menu__link" to="/"><span>Home Page</span></Link>
                                    </li>

                                

                                    
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/activities"><span>Activities</span></Link>
                                    </li>

                                  

                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/communities"><span>Communities</span></Link>
                                    </li>
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/project/54"><span>Ongoing Project</span></Link>
                                    </li>
                                    
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/about"><span>About</span></Link>
                                    </li>


                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/news"><span>News</span></Link>
                                    </li>
                                  
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/videogallery"><span>Video Gallery</span></Link>
                                    </li>

                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/project/55"><span>Upcoming Project</span></Link>
                                    </li>

                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/team"><span>Team</span></Link>
                                    </li>
                                  
                                   

                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/events"><span>Events</span></Link>
                                    </li>
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/photogallery"><span>Photo Gallery</span></Link>
                                    </li>
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/project/56"><span>Completed Project</span></Link>
                                    </li>
                                   
                                    <li className="footer-menu__item">
                                        <Link className="footer-menu__link" to="/workPlan"><span>Work Plan</span></Link>
                                    </li>
                                    
                                    
                                    
                                    
                                </ul>
                            </nav>
                            {/* <!-- footer nav end--> */}
                        </div>
                    </div>
                </div>
                <div className="footer__lower">
                    <div className="container">
                        <div className="row align-items-baseline">
                            <div className="col-md-6 col-xl-7">
                                <p className="footer-copyright">© 2023 GICTM</p>
                            </div>
                            <div className="col-md-6 col-xl-5">
                                <div className="footer__form">
                                    <input className="footer__form-input" type="email" placeholder="Enter your E-mail" />
                                    <button className="footer__form-submit button button--primary" type="submit">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- footer front_3 end--> */}
        </>
    );
};

export default Footer;